// api key: AIzaSyAnNTG7yZnF1eDGqZQ800Uxv41FvqDOeiM

import { LottieElement } from "@bond-london/gatsby-graphcms-components/dist/elements/LottieElement";
import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useCallback, useEffect, useState } from "react";

// import facebook from "../images/pip-facebook.svg";
// import twitter from "../images/pip-twitter.svg";
import instagram from "../images/pip-instagram.svg";
import linkedin from "../images/pip-linkedin.svg";
import logo from "../images/pip-logo.svg";
import smallLogo from "../images/pip-small-logo.svg";
import { useFirstVisibleToUser } from "../hooks";
import {
  GraphCms_Colour,
  GraphCms_Treatment,
} from "../generated/graphql-types";
import { getTreatmentClassName, lookupColour } from "../utils";
import { CustomSearch, I18Link } from ".";
import { LottieInformation } from "@bond-london/gatsby-graphcms-components";
import { Link } from "gatsby";
// import sliderLogo from "../images/afr-badge.png";

interface Props {
  image?: IGatsbyImageData;
  video?: string;
  animation?: LottieInformation;
  title: string;
  short?: boolean;
  headings?: readonly string[];
  className?: string;
  showSearch?: boolean;
  animationEnabled?: boolean;
  treatment?: GraphCms_Treatment;
  colour?: GraphCms_Colour;
  subheading?: string;
}

const Hero: React.FC<Props> = ({
  image,
  video,
  animation,
  title,
  short,
  headings,
  className,
  showSearch,
  animationEnabled,
  treatment,
  colour,
  subheading,
}) => {
  const [containerRef, containerVisible] = useFirstVisibleToUser();
  const [startAnimating, setStartAnimating] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(!image);
  const handleLoaded = useCallback(() => setImageLoaded(true), []);
  const handleStartLoad = useCallback(
    ({ wasCached }: { wasCached?: boolean }) => {
      if (wasCached) setImageLoaded(true);
    },
    []
  );
  useEffect(() => {
    if (containerVisible && imageLoaded) {
      setStartAnimating(true);
    }
  }, [containerVisible, imageLoaded]);

  const hasHeadings =
    (headings && headings.length === 2) || headings?.length === 3;

  return (
    <section
      data-component="Hero"
      ref={containerRef}
      className={classNames(
        "w-full relative page-grid auto-rows-auto overflow-hidden",
        short && !hasHeadings && "h-350px",
        colour && lookupColour(colour, "Background")
      )}
    >
      {!!colour ||
        (!image && !video && (
          <div
            className={classNames(
              short ? "min-h-350px" : "min-h-700px",
              hasHeadings ? "row-span-3" : "row-span-2",
              "row-start-1",
              "col-start-1 col-span-full",
              className ? className : "bg-light-blue"
            )}
          />
        ))}
      {!colour && image && (
        <GatsbyImage
          onLoad={handleLoaded}
          onStartLoad={handleStartLoad}
          onError={handleLoaded}
          objectFit="cover"
          objectPosition="center"
          style={{ position: "absolute" }}
          className={classNames(
            "h-full w-full top-0 left-0 bottom-0 right-0",
            getTreatmentClassName(treatment)
          )}
          image={image}
          alt="Hero"
        />
      )}
      {!colour && video && (
        <video
          onPlay={handleLoaded}
          autoPlay={true}
          muted={true}
          loop={true}
          src={video}
          className={classNames(
            getTreatmentClassName(treatment),
            "absolute h-full w-full top-0 left-0 right-0 bottom-0",
            short ? "object-center" : "object-right md:object-left",
            "object-cover"
          )}
        />
      )}
      {/* {animation && (
        <LottieElement
          alt="Animation"
          className={classNames(
            "hidden md:block",
            "relative row-start-1 col-start-1 col-span-6",
            "self-end",
            "transform-gpu translate-x-3/12",
            hasHeadings ? "row-span-3" : "row-span-2"
          )}
          animationUrl={animation.animationUrl}
          encoded={animation.encoded}
        />
      )} */}
      {animation && (
        <>
          <a
            // href="https://info.pip.global/one-of-afr-boss-most-innovative-companies-2023"
            href="/en/subscribe/"
            className={classNames(
              "hidden md:block",
              "relative row-start-1 col-start-1 col-span-4",
              "self-end",
              "transform-gpu translate-x-3/12",
              hasHeadings ? "row-span-3" : "row-span-2"
            )}
          >
            <LottieElement
              alt="Animation"
              animationUrl={animation.animationUrl}
              encoded={animation.encoded}
            />
          </a>
          {/* <a
            className={classNames(
              "hidden md:block",
              "relative row-start-1 col-start-1 col-span-6",
              "self-end",
              "transform-gpu translate-x-3/12",
              "w-desktop-4-cols h-auto m-auto ga-home-image",
              hasHeadings ? "row-span-3" : "row-span-2"
            )}
            href="https://info.pip.global/one-of-afr-boss-most-innovative-companies"
          >
            <img src={sliderLogo} />
          </a> */}
        </>
      )}

      {/* <section className="hidden fixed top-20px right-30px lg:grid grid-cols-1 auto-rows-auto z-20 gap-y-20px pt-20px mix-blend-color-dodge"> */}
      <section className="hidden fixed top-20px right-30px lg:grid grid-cols-1 auto-rows-auto z-20 gap-y-20px pt-20px">
        {/* <a
          href="https://www.facebook.com/lifeatpip/"
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
        >
          <img src={facebook} alt="facebook icon" />
        </a> */}
        <a
          href="https://www.instagram.com/lifeatpip/"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
        >
          <img src={instagram} alt="instagram icon" />
        </a>
        <a
          href="https://www.linkedin.com/company/partnersinperformance/"
          target="_blank"
          rel="noreferrer"
          aria-label="LinkedIn"
        >
          <img src={linkedin} alt="linkedin icon" />
        </a>
        <p className="text-grey sideways small-body-copy ga-grey">⎯⎯⎯⎯⎯⎯⎯⎯</p>
        <p className="text-grey sideways small-body-copy ga-grey">Follow us</p>
      </section>
      {!subheading && (
        <h1
          className={classNames(
            short ? "h2" : "h1",
            "pt-96px",
            "row-start-2 row-span-1",
            "col-start-2 col-span-12",
            short
              ? "md:col-start-2 md:col-span-12"
              : "md:col-start-8 md:col-span-6",
            hasHeadings ? "self-end pb-40px" : "self-center pb-section",
            "text-white-2 z-10",
            animationEnabled && "animate-slideInFromTop animation-delay-500"
          )}
        >
          {title}
        </h1>
      )}
      {subheading && (
        <h1
          className={classNames(
            short ? "h2" : "h1",
            "pt-96px",
            "row-start-2 row-span-1",
            "col-start-2 col-span-12",
            short
              ? "md:col-start-2 md:col-span-12"
              : "md:col-start-8 md:col-span-6",
            hasHeadings ? "self-end pb-40px" : "self-center pb-section",
            "text-white-2 z-10",
            animationEnabled && "animate-slideInFromTop animation-delay-500"
          )}
        >
          <Link to={"/en/wiredup"} target="_blank">
            {title}
          </Link>
          {subheading && <h5 className="h3">{subheading}</h5>}
        </h1>
      )}
      {hasHeadings && (
        <div
          className={classNames(
            "pb-section",
            "z-10",
            "row-start-3 row-span-1",
            "col-start-2 col-span-12",
            short
              ? "md:col-start-2 md:col-span-12"
              : "md:col-start-8 md:col-span-6",
            "self-center",
            "text-white-2",
            animationEnabled && startAnimating
              ? "animation-running"
              : "animation-paused",
            animationEnabled && "animation-delay-1000 animate-slideInFromRight"
          )}
        >
          {headings?.map((heading, headingIndex) => (
            <h2 key={headingIndex} className={classNames("h2")}>
              {heading}
            </h2>
          ))}
        </div>
      )}
      <I18Link
        to="/"
        className="hidden md:block absolute left-30px top-20px animate-slideInFromLeft"
      >
        <img src={logo} alt="logo" className="w-full" />
      </I18Link>
      <I18Link
        to="/"
        className="md:hidden absolute top-30px left-0 ml-mobile-margin animate-slideInFromLeft"
      >
        <img src={smallLogo} alt="small logo" />
      </I18Link>
      {showSearch && (
        // <CustomSearch className="hidden lg:block relative row-start-1 row-span-1 col-start-8 col-span-6 pt-20px z-10 animate-slideInFromTop" />
        <CustomSearch className="hidden lg:block relative row-start-1 row-span-1 col-start-11 col-span-3 pt-20px z-10 animate-slideInFromTop" />
      )}
    </section>
  );
};

export default Hero;
